/* Entry Animations */
.fade-in {
	animation: fadeInAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	animation: fadeOutAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fade-in-up {
	animation: fadeUpAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeUpAnimation {
	0% {
		opacity: 0;
		transform: translateY(20%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

.fade-in-down {
	animation: fadeDownAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeDownAnimation {
	0% {
		opacity: 0;
		transform: translateY(-20%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

.fade-in-left {
	animation: fadeLeftAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeLeftAnimation {
	0% {
		opacity: 0;
		transform: translateX(-20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

.fade-in-right {
	animation: fadeRightAnimation ease 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeRightAnimation {
	0% {
		opacity: 0;
		transform: translateX(20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

/* Bar Loader */
.page-loader {
	@apply relative w-full h-1 overflow-hidden transition-all duration-300 bg-light;
}

.page-loader.hide {
	@apply h-0;
}

.page-loader::after {
	@apply absolute top-0 left-0 w-64 h-1;

	background: linear-gradient(to right, rgba(27, 219, 23, 0) 0%, rgb(219, 23, 23) 50%, rgba(27, 219, 23, 0) 100%);

	content: "";

	box-sizing: border-box;

	animation: hitZak 1.2s ease-in-out infinite alternate;
}

@keyframes hitZak {
	0% {
		left: 0;

		transform: translateX(-1%);
	}
	100% {
		left: 100%;

		transform: translateX(-99%);
	}
}

/* Payment Loader */
.payment-loader {
	@apply relative inline-block w-full h-2 overflow-hidden bg-light;
}

.payment-loader::after {
	@apply box-border absolute top-0 left-0 w-32 h-2 rounded-full bg-primary;

	content: "";

	animation: hitZak 0.6s ease-in-out infinite alternate;
}

@keyframes hitZak {
	0% {
		left: 0;

		transform: translateX(-1%);
	}
	100% {
		left: 100%;

		transform: translateX(-99%);
	}
}

/* Animated Tick */

.checkmark_circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #1c751a;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	margin: auto;
	box-shadow: inset 0px 0px 0px #1c751a;
	animation:
		fill 0.4s ease-in-out 0.4s forwards,
		scale 0.3s ease-in-out 0.9s both;
}

.checkmark_check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #1c751a;
	}
}

.floating {
	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes floating {
	0% {
		transform: translate(0, 0px);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, -0px);
	}
}

.role-switch {
	transition: all 0.4 ease-in;
	animation: roleSwitch ease 0.4s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes roleSwitch {
	0% {
		scale: 1;
		opacity: 1;
	}
	100% {
		scale: 0.3;
		opacity: 0;
	}
}

.rotating {
	-webkit-animation: spin 4s linear infinite;
	-moz-animation: spin 4s linear infinite;
	animation: spin 10s linear infinite;
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.assistant-loader,
.assistant-loader:before,
.assistant-loader:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	animation-fill-mode: both;
	animation: bblFadInOut 1.8s infinite ease-in-out;
}
.assistant-loader {
	color: #fff;
	font-size: 7px;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;
}
.assistant-loader:before,
.assistant-loader:after {
	content: "";
	position: absolute;
	top: 0;
}
.assistant-loader:before {
	left: -3.5em;
	animation-delay: -0.32s;
}
.assistant-loader:after {
	left: 3.5em;
}

@keyframes bblFadInOut {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}

.ocr-animation {
	position: relative;
	width: 108px;
	display: flex;
	justify-content: space-between;
}
.ocr-animation::after,
.ocr-animation::before {
	content: "";
	display: inline-block;
	width: 48px;
	height: 48px;
	background-color: #fff;
	background-image: radial-gradient(circle 14px, #0d161b 100%, transparent 0);
	background-repeat: no-repeat;
	border-radius: 50%;
	animation:
		eyeMove 10s infinite,
		blink 10s infinite;
}
@keyframes eyeMove {
	0%,
	10% {
		background-position: 0px 0px;
	}
	13%,
	40% {
		background-position: -15px 0px;
	}
	43%,
	70% {
		background-position: 15px 0px;
	}
	73%,
	90% {
		background-position: 0px 15px;
	}
	93%,
	100% {
		background-position: 0px 0px;
	}
}
@keyframes blink {
	0%,
	10%,
	12%,
	20%,
	22%,
	40%,
	42%,
	60%,
	62%,
	70%,
	72%,
	90%,
	92%,
	98%,
	100% {
		height: 48px;
	}
	11%,
	21%,
	41%,
	61%,
	71%,
	91%,
	99% {
		height: 18px;
	}
}
