
.card {
width: 320px;
height: 200px;
border-radius: 15px;
perspective: 1000px;
}
.card:hover .card-inner {
transform: rotateY(180deg);
}
.card-inner {
position: relative;
width: 100%;
height: 100%;
border-radius: 15px;
transition: transform 600ms ease;
transform-style: preserve-3d;
}
.card-front, .card-back {
position: absolute;
top: 0;
width: 100%;
height: 100%;
border-radius: 15px;
overflow: hidden;
backface-visibility: hidden;
}
.card-front {
border-radius: 15px;
overflow: hidden;
position: relative;
transition: transform 300ms ease-in-out;
}
.card-back {
transform: rotateY(180deg);
background: linear-gradient(321.03deg, #000 0%, #444 91.45%);
}
.card-back::before {
content: "";
position: absolute;
top: 40%;
left: 20%;
width: 180%;
height: 120%;
border-radius: 100%;
filter: blur(10px);
opacity: 0.15;
}
.card-back::after {
@apply bg-accent;
content: "";
position: absolute;
top: 15%;
width: 100%;
height: 40px;
}
.card-bg {
position: absolute;
top: -20px;
right: -120px;
width: 380px;
height: 250px;
border-top-left-radius: 100%;
}
.card-bg::before {
content: "";
position: absolute;
top: -20px;
right: -80px;
width: 380px;
height: 250px;
border-top-left-radius: 100%;
}
.card-bg::after {
content: "";
position: absolute;
top: -20px;
right: -120px;
width: 380px;
height: 250px;
border-top-left-radius: 100%;
}
.card-glow {
position: absolute;
top: -140px;
left: -65px;
height: 200px;
width: 400px;
filter: blur(10px);
border-radius: 100%;
transform: skew(-15deg, -15deg);
}
.card-contactless {
position: absolute;
right: 15px;
top: 55px;
transform: scale(0.5);
}
.card-chip {
position: absolute;
top: 65px;
left: 25px;
width: 45px;
height: 34px;
border-radius: 5px;
background-color: #ffda7b;
overflow: hidden;
}
.card-chip::before {
content: "";
position: absolute;
left: 49%;
top: -7%;
transform: translateX(-50%);
background: #ffda7b;
border: 1px solid #a27c1f;
width: 25%;
height: 110%;
border-radius: 100%;
z-index: 2;
}
.card-chip::after {
content: "";
position: absolute;
top: 30%;
left: -10%;
background: transparent;
border: 1px solid #a27c1f;
width: 120%;
height: 33%;
}
.card-holder {
position: absolute;
left: 25px;
bottom: 30px;
color: white;
font-size: 14px;
letter-spacing: 0.2em;
filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
}
.card-number {
position: absolute;
left: 25px;
bottom: 65px;
color: white;
font-size: 16px;
font-weight: 600;
letter-spacing: 0.2em;
filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
}
.card-valid {
position: absolute;
left: 70px;
top: 125px;
color: white;
font-size: 14px;
letter-spacing: 0.2em;
filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
}
.card-valid::before {
content: "EXPIRY";
position: absolute;
top: 6px;
left: -45px;
width: 50px;
font-size: 7px;
}
.card-seccode {
position: absolute;
top: 125px;
left: 245px;
display: flex;
justify-content: center;
align-items: center;
width: 60px;
height: 17px;
color: #021318;
background-color: #eeecec;
border-radius: 4px;
text-align: center;
font-size: 11px;
}

.logo {
position: absolute;
right: 25px;
top: 0px;
}

.hint {
padding: 2em 0;
letter-spacing: 0.025em;
font-weight: 400;
color: #a3d4e7;
}